import logo from '../assets/images/app/onlink.png';
import '../App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="card" alt="logo" />
        
      </header>
    </div>
  );
}

export default App;
